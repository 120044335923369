import {getActivePinia, storeToRefs,} from "pinia"
import {useTextStore,} from "@/assets/js/src/modules/text/_pinia/text"
import {computed,} from "vue"
import {useBibleStore,} from "@/assets/js/src/modules/bible/_pinia/bible"
import {useLangStore,} from "@/assets/js/src/modules/lang/_pinia/lang"
import {useBooknamesStore,} from "@/assets/js/src/modules/bible/_pinia/booknames"

export function useTextInfo () {
    const activePinia = getActivePinia()

    const {
        chapters,
        selectedVersesAsArray: selectedVerses,
    } = storeToRefs(useTextStore(activePinia))

    const {
        guiLanguage,
    } = storeToRefs(useLangStore(activePinia))

    const {
        getBibleById,
    } = storeToRefs(useBibleStore(activePinia))

    const {
        getDefaultBooknameByNumber,
        getShortBooknameByNumber,
    } = storeToRefs(useBooknamesStore(activePinia))

    const firstBible = computed(() => {
        let bibleId = firstChapter.value.bible?.id

        return getBibleById.value(bibleId) || {}
    })
    
    const firstChapter = computed(() => {
        return (chapters.value[0] || {}).chapter || {}
    })

    const chapterIds = computed(() => {
        return chapters.value.map((chapter) => chapter.chapter.id)
    })

    const number = computed(() => {
        return firstChapter.value.number || ''
    })

    const selectedRefString = computed(() => {
        let sep = (selectedVerses.value.length !== 0) ? getLocaleSepChapter.value : ''

        return `${bookname.value}${getLocaleSepBook.value}${number.value}${sep}${selectedVersesString.value}`.trim()
    })

    const selectedVersesString = computed(()=> {
        let vers_sep = firstBible.value.sep_verse
        let range_sep = firstBible.value.sep_range
        let verses = ''
        selectedVerses.value.forEach((verseRange) => {
            verses += verseRange[0]

            if (verseRange[0] !== verseRange[1]) {
                verses += vers_sep + verseRange[1]
            }
            verses += range_sep
        })

        if (verses.length !== 0) {
            verses = verses.replace(new RegExp(range_sep + '$'), '')
        }

        return verses
    })

    const getLocaleSepChapter = computed(() => {
        return guiLanguage.value?.sep_chapter
    })

    const getLocaleSepBook = computed(() => {
        return guiLanguage.value?.sep_book
    })


    const bookname = computed(() => {
        let bookNumber = Math.floor((firstChapter.value.canonical || 0) / 1000000)

        return bookNumber ?
            getDefaultBooknameByNumber.value(bookNumber) : ''
    })

    const shortBookname = computed(()=> {
        let bookNumber = Math.floor((firstChapter.value.canonical || 0) / 1000000)

        return bookNumber ?
            getShortBooknameByNumber.value(bookNumber) : ''
    })

    const selectedShortRefString = computed(() => {
        let sep = (selectedVerses.value.length !== 0) ? getLocaleSepChapter.value : ''

        return `${shortBookname.value}${getLocaleSepBook.value}${number.value}${sep}${selectedVersesString.value}`
    })
    
    return {
        firstChapter,
        chapterIds,
        number,
        firstBible,
        selectedRefString,
        selectedVersesString,
        selectedShortRefString,
        bookname,
        shortBookname,
    }
}