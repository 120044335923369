import {computed, onMounted, watch, nextTick,} from "vue"
import {useUserOptions,} from "@/assets/js/src/modules/user/_composables/useUserOptions"
import {getActivePinia, storeToRefs,} from "pinia"
import {useTextStore,} from "@/assets/js/src/modules/text/_pinia/text"
import {useRoute,} from "vue-router"
import {useComponentIsActive,} from "../../../util/composables/useComponentIsActive"

export function useTextFormats () {
    const activePinia = getActivePinia()
    const {getUserOption,} = useUserOptions()
    const currentRoute = useRoute()
    const {componentIsActive,} = useComponentIsActive()

    const {
        chapters,
        selectedVersesAsArray: selectedVerses,
        existsVerseSelection,
    } = storeToRefs(useTextStore(activePinia))

    const textFormats = computed(()=> {
        return getUserOption('textFormats')
    })

    const textFormatsClasses = computed(()=> {
        let classes = []

        classes.push('type-' + textFormats.value.font)
        classes.push('size-' + (textFormats.value.fontSize + 1))

        if (textFormats.value.oneVerseLine || currentRoute.meta.isCompare) {
            classes.push('textformat--oneverseperline')
            classes.push('grid-columns-' + chapters.value.length)
        }

        if (textFormats.value.showVerseNumbers) {
            classes.push('textformat--show-verse-numbers')
        }

        if (textFormats.value.showCrossRefs) {
            classes.push('textformat--show-cross-refs')
        }

        if (textFormats.value.showFootnotes) {
            classes.push('textformat--show-verse-footnotes')
        }

        if (textFormats.value.maxWidth) {
            classes.push('textformat--max-width')
        }

        if (currentRoute.meta.isCompare) {
            classes.push('textformat--is-compare')
        }

        if (textFormats.value.showHeadings) {
            classes.push('textformat--show-headings')
            if (existsVerseSelection.value) {
                let verses = [ ...new Set(selectedVerses.value.flatMap((elem) => {
                    let tmp = []

                    for (let i = elem[0]; i <= elem[elem.length - 1]; i++) {
                        tmp.push(i)
                    }

                    return tmp
                })), ].map((elm) => 'print-heading-' + elm)

                classes.push(...verses)
            }
        }

        if (existsVerseSelection.value) {
            let verses = [ ...new Set(selectedVerses.value.flatMap((elem) => {
                let tmp = []

                for (let i = elem[0]; i <= elem[elem.length - 1]; i++) {
                    tmp.push(i)
                }

                return tmp
            })), ].map((elm) => 'v' + elm)

            classes.push(...verses)
            classes.push('textformat--exists-selection')
        }

        return classes
    })

    const showHideData = {
        'data-tf-refs': textFormats.value.showCrossRefs ? 'show' : 'hide',
        'data-tf-fn': textFormats.value.showFootnotes ? 'show' : 'hide',
        'data-tf-vn': textFormats.value.showVerseNumbers ? 'show' : 'hide',
        'data-tf-h': textFormats.value.showHeadings ? 'show' : 'hide',
    }

    onMounted(() => {
        let watchers = [
            {varRef: 'showCrossRefs', class: 'show-cross-refs', data: 'tfRefs',},
            {varRef: 'showFootnotes', class: 'show-verse-footnotes', data: 'tfFn',},
            {varRef: 'showVerseNumbers', class: 'show-verse-numbers', data: 'tfVn',},
            {varRef: 'showHeadings', class: 'show-headings', data: 'tfH',},
        ]
        watchers.forEach((watcher) => {
            watch(
                () => {
                    return textFormats.value[watcher.varRef] && componentIsActive.value
                },
                (newValue, oldValue) => {
                    if (newValue !== oldValue) {
                        nextTick(() => {
                            let selector = newValue ?
                                `.text-view.textformat--${watcher.class}` :
                                `.text-view:not(.textformat--${watcher.class})`
                            let wrapper = document.querySelector('.text-view-wrapper')
                            if(!wrapper) {
                                return
                            }

                            let elem = wrapper.querySelector(selector)
                            if(!elem) {
                                return
                            }

                            elem.dataset[watcher.data] = ''
                            if (newValue) {
                                // erst nachdem text-formats-classe gesetzt wurde, opacity setzen
                                setTimeout(() => {
                                    elem.dataset[watcher.data] = 'show'
                                }, 20)
                            } else {
                                // erst nachdem die opacity transition zu ende ist, display:none setzen
                                let handler = () => {
                                    elem.dataset[watcher.data] = 'hide'
                                    wrapper?.removeEventListener('transitionend', handler)
                                }
                                wrapper?.addEventListener('transitionend', handler)
                            }
                        })
                    }
                }
            )
        })
    })
    
    return {
        textFormats,
        textFormatsClasses,
        showHideData,
    }
}