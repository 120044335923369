import {computed,} from "vue"
import {getActivePinia, storeToRefs,} from "pinia"
import {useRoute,} from "vue-router"
import {useRouteMetaStore,} from "@/assets/js/src/pinia/routeMeta"
import {useBibleStore,} from "@/assets/js/src/modules/bible/_pinia/bible"
import {useTextStore,} from "@/assets/js/src/modules/text/_pinia/text"
import {useMessagesLoaded,} from "@/assets/js/src/modules/lang/_composables/useMessagesLoaded"
import {useTranslation,} from "@/assets/js/src/util/composables"
import {useTextInfo,} from "@/assets/js/src/modules/text/_composables/useTextInfo"
import {getActiveHead,} from "@/assets/js/src/util/composables/useActiveHead"
import {useHeadLinkInfo,} from "@/assets/js/src/modules/text/_composables/useHeadLinkInfo"
import {genHeadOverrides,} from "@/assets/js/src/layout/meta"

export const useHeadInfo = () => {
    const {messagesLoaded,} = useMessagesLoaded([ 'text', ])
    const {t,} = useTranslation()
    const activePinia = getActivePinia()
    const currentRoute = useRoute()
    const head = getActiveHead()

    const {
        prevUrl,
        nextUrl,
        autocomplete,
    } = storeToRefs(useRouteMetaStore(activePinia))
    
    const {
        hasAmp,
        selectedBibles,
        getNameByAbbreviation,
        getSepChapterByAbbreviation,
    } = storeToRefs(useBibleStore(activePinia))
    
    const {
        selectedVersesAsArray: selectedVerses,
    } = storeToRefs(useTextStore(activePinia))

    const {
        firstChapter,
        number,
        selectedRefString,
        selectedVersesString,
        shortBookname,
    } = useTextInfo()

    const {
        current,
        linkCurrent,
        linkNext,
        linkPrev,
    } = useHeadLinkInfo()

    const title = computed(()=> {
        let abbr = currentRoute.params.abbreviation || 'LUT'
        let bibleName = getNameByAbbreviation.value(abbr)

        return `${selectedRefString.value || autocomplete.value} | ${bibleName}` // den Bookname über Book id -> number holen
    })

    const mostLikedVerse = computed(()=> {
        return firstChapter.value?.mostLikedVerse
    })

    const description = computed(()=> {
        if (currentRoute.meta.uiType !== 'TextView') {
            return ''
        }
        if (selectedVerses.value.length === 0) {
            if ((firstChapter.value.verses || []).length === 0) {
                return messagesLoaded.value ? t('async.text.no_chapter') : ''
            } else {
                if (mostLikedVerse.value) {
                    let mostLikedVerseEntity = firstChapter.value.verses.find((verse) => verse.canonical === mostLikedVerse.value)
                    if (mostLikedVerseEntity) {
                        return mostLikedVerseEntity.content
                    }
                }

                return `${firstChapter.value.verses[0].content} [...]`
            }
        } else {
            let abbr = currentRoute.params.abbreviation || 'LUT'
            let ff = (selectedVerses.value[0][0] !== selectedVerses.value[0][1]) ? '[...]' : ''
            let verse = (firstChapter.value.verses || [])[selectedVerses.value[0][0] - 1] || {}

            return `${verse.content} ${ff} (${shortBookname.value} ${number.value}${getSepChapterByAbbreviation.value(abbr)}${selectedVersesString.value}; ${abbr})`
        }
    })

    const ampCanonical = computed(()=> {
        let ampEntry = ''
        let isAmp = selectedBibles.value.every(abbreviation => hasAmp.value(abbreviation))

        if (isAmp) {
            ampEntry = `${activePinia.state.value.baseUrl}/amp${current.value}`
        }

        return ampEntry
    })

    let info = genHeadOverrides({title, description, ampCanonical, canonical: linkCurrent,})
    if((nextUrl.value || '').length === 0) {
        info.link.push({
            rel: 'next', // Nur in Text Kontroller
            href: linkNext,
            title: 'Next Chapter',
            vmid: 'next_chapter',
        })
    }
    if((prevUrl.value || '').length === 0) {
        info.link.push({
            rel: 'prev', // Nur in Text Kontroller
            href: linkPrev,
            title: 'Prev Chapter',
            vmid: 'prev_chapter',
        })
    }

    head.push(info)
}
