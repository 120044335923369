import {INPUT_TYPE_TEXT,} from "@/assets/js/src/modules/sidebar/_pinia/_constants"
import {computed, watch,} from "vue"
import {useComponentIsActive,} from "@/assets/js/src/util/composables/useComponentIsActive"
import {getActivePinia, storeToRefs,} from "pinia"
import {useTextInfo,} from "@/assets/js/src/modules/text/_composables/useTextInfo"
import {useHeadLinkInfo,} from "@/assets/js/src/modules/text/_composables/useHeadLinkInfo"
import {useTextStore,} from "@/assets/js/src/modules/text/_pinia/text"
import {useRouteMetaStore,} from "@/assets/js/src/pinia/routeMeta"
import {useUserOptions,} from "@/assets/js/src/modules/user/_composables/useUserOptions"
import {useRoute,} from "vue-router"

export function useSidebarInfo () {
    const activePinia = getActivePinia()
    const {componentIsActive,} = useComponentIsActive()
    const {getUserOption,} = useUserOptions()
    const currentRoute = useRoute()

    const {
        selectedVersesAsSet,
    } = storeToRefs(useTextStore(activePinia))

    const {
        autocomplete,
        chapterCanonical,
    } = storeToRefs(useRouteMetaStore(activePinia))

    const {
        firstBible,
        firstChapter,
        selectedVersesString,
        selectedRefString,
        selectedShortRefString,
    } = useTextInfo()

    const {
        current,
    } = useHeadLinkInfo()

    const linkShare = computed(() => {
        let verses = ''
        if (selectedVersesString.value.length !== 0) {
            verses = firstBible.value.sep_chapter + selectedVersesString.value
        }

        let share = `${current.value}${verses}`
        
        return activePinia.state.value.baseUrl + share
    })

    let linkShareNotReactive = linkShare.value
    
    const sidebarInput = computed(() => {
        let shareLink = linkShare.value
        if (!componentIsActive.value) {
            shareLink = linkShareNotReactive
        }

        return {
            type: INPUT_TYPE_TEXT,
            data: firstChapter.value.canonical || chapterCanonical.value,
            filter: selectedVersesAsSet.value || [],
            shareLink,
            title: selectedRefString.value || autocomplete.value,
            shortTitle: selectedShortRefString.value,
            isCompare: currentRoute.meta.isCompare,
        }
    })
   
    watch(linkShare, (newValue) => {
        if (componentIsActive.value) {
            linkShareNotReactive = newValue
        }
    },{immediate: true,})
    
    return {
        sidebarInput,
        isSidebarOpen: computed(() => {
            return getUserOption('sidebar/open')
        }),
    }
}
