import {getActivePinia, storeToRefs,} from 'pinia'
import {useRoute,} from 'vue-router'
import {LOADED, usePrefetchData,} from '@/assets/js/src/util/composables/usePrefetchData'
import {useTextStore,} from '@/assets/js/src/modules/text/_pinia/text'
import {useComponentIsActive,} from '@/assets/js/src/util/composables/useComponentIsActive'
import {computed,} from 'vue'

export function useTextApi () {
    let activePinia = getActivePinia()
    let textStore = useTextStore(activePinia)
    let {chapters,} = storeToRefs(textStore)
    let route = useRoute()

    let {messagesLoaded,updateData,asyncStatus,} = usePrefetchData({
        actions: [
            {
                item: chapters,
                action: textStore.fetchChapterByRef,
                payload: function () {
                    return route
                },
            },
        ],
        loadAsyncMessages: [ 'text', 'sidebar', 'tag', 'note', 'nicodemus-ai', 'common', 'menu', ],
    })

    let {componentIsActive,} = useComponentIsActive()

    const asyncStatusLoaded = computed(()=>asyncStatus.value === LOADED)

    return {
        messagesLoaded,
        asyncStatusLoaded,
        componentIsActive,
        updateData,
        chapters,
    }
}