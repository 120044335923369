import {computed,} from "vue"
import {getActivePinia, storeToRefs,} from "pinia"
import {useRoute,} from "vue-router"
import {useBibleStore,} from "@/assets/js/src/modules/bible/_pinia/bible"
import {useRouteMetaStore,} from "@/assets/js/src/pinia/routeMeta"
import {useTextInfo,} from "@/assets/js/src/modules/text/_composables/useTextInfo"

export function useHeadLinkInfo () {
    const activePinia = getActivePinia()
    const currentRoute = useRoute()

    const {
        selectedBibles,
    } = storeToRefs(useBibleStore(activePinia))

    const {
        firstBible,
    } = useTextInfo()

    const {
        bibles: routeMetaBibles,
        prevUrl,
        nextUrl,
    } = storeToRefs(useRouteMetaStore(activePinia))
    
    const bibles = computed(()=> {
        return selectedBibles.value.join('.')
    })
    
    const current = computed(()=> {
        let textRef = ''
        try {
            textRef = decodeURIComponent(currentRoute.fullPath.split('/')[2])
        } catch (e) {
            textRef = decodeURIComponent((currentRoute?.path || '//').split('/')[2])
        }

        return `/${encodeURIComponent(bibles.value)}/${encodeURIComponent(textRef.split(firstBible.value.sep_chapter)[0])}`
    })

    const linkCurrent = computed(()=> {
        return activePinia.state.value.baseUrl + current.value
    })

    const prev = computed(()=> {
        return prevUrl.value.replace(
            routeMetaBibles.value.join('.'),
            encodeURIComponent(bibles.value)
        )
    })
    const linkPrev = computed(()=> {
        return activePinia.state.value.baseUrl + prev.value
    })

    const next = computed(()=> {
        return nextUrl.value.replace(
            routeMetaBibles.value.join('.'),
            encodeURIComponent(bibles.value)
        )
    })
    const linkNext = computed(()=> {
        return activePinia.state.value.baseUrl + next.value
    })
    
    return {
        current,
        linkCurrent,
        prev,
        linkPrev,
        next,
        linkNext,
    }
}