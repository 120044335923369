<script setup>
import Verse from '@/assets/js/src/modules/text/_components/Verse.vue'
import Headings from '@/assets/js/src/modules/text/_components/VerseHeadings.vue'
import {DIR_LTR, DIR_RTL,} from '@/assets/js/src/modules/lang/_pinia/lang'
import {useTranslation,} from "@/assets/js/src/util/composables/useTranslation"

defineProps({
    chapter: {
        type: Object,
        default: () => {
        },
    },
    order: {
        type: Number,
        default: 0,
    },
    chapterMeta: {
        type: Object,
        default: () => {
        },
    },
    bible: {
        type: Object,
        default: () => {
        },
    },
    bookname: {
        type: String,
        default: '',
    },
})

defineEmits([ 'select-section', 'select-verse', 'change-order', ])

const {t,} = useTranslation()
</script>

<template>
    <!-- Kapitel einer Übersetzung -->
    <article
        v-once
        :ref="'chapter_'+ chapter.id"
        class="chapter"
        :dir="chapter.bible.language.rtl ? DIR_RTL : DIR_LTR"
        :lang="chapter.bible.language.locale"
    >
        <!-- Border -->
        <div
            class="chapter-border"
            :style="'grid-row-start:1;grid-row-end:800'"
        >
            <v-btn
                :title="t('async.common.button.swap')"
                aria-hidden="true"
                variant="text"
                icon
                @click="$emit('change-order', chapter.id)"
            >
                <v-icon>bs:$vuetify.icons.mdiSwapHorizontal</v-icon>
            </v-btn>
        </div>

        <!-- Bibelstelle und Übersetzung -->
        <header style="grid-row: 1 / 2">
            <h1 class="d-sr-only hidden-print-only">
                {{ bookname }} {{ chapter.number }}
            </h1>
            <h2 class="bible-name">
                {{ chapter.bible.name }}
            </h2>
        </header>
        <!-- Verse des Kapitels -->
        <template
            v-for="(verse, index) in chapter.verses"
            :key="'heading_' + verse.id + '_' + index"
        >
            <!-- evtl. Vers-Überschriften -->
            <headings
                :verse="verse"
                :slug="chapterMeta['heading-slugs']['slug_' + verse.verse]"
                :pos="typeof chapterMeta['rows'][index] !== 'undefined' ? `grid-row-start:${chapterMeta['rows'][index][0]};grid-row-end:${chapterMeta['rows'][index][1]};`:''"
                @select-section="$emit('select-section',$event,verse,chapter.id)"
            />
            <headings
                :verse="verse"
                :slug="chapterMeta['heading-slugs']['subslug_' + verse.verse]"
                :small="true"
                :pos="typeof chapterMeta['rows'][index] !== 'undefined' ? `grid-row-start:${chapterMeta['rows'][index][1]};grid-row-end:${chapterMeta['rows'][index][2]};`:''"
                @select-section="$emit('select-section',$event,verse,chapter.id)"
            />
            <!-- Vers -->
            <verse
                :verse="verse"
                :bible-id="chapter.bible.id"
                :dir="chapter.bible.language.rtl ? DIR_RTL : DIR_LTR"
                :verse-footnotes="chapterMeta['verse-footnotes']['v' + verse.verse]"
                :verse-references="chapterMeta['verse-references']['v' + verse.verse]"
                :verse-references-google="chapterMeta['verse-references-google']['v' + verse.verse]"
                :verse-style="typeof chapterMeta['rows'][index] !== 'undefined' ? `grid-row-start:${chapterMeta['rows'][index][2]};grid-row-end:${chapterMeta['rows'][index][3]};`:''"
                @select-verse="$emit('select-verse', $event, verse)"
            />
        </template>
        <!-- leeres Kapitel -->
        <p
            v-if="chapter.verses.length === 0"
            class="type-default scroll-elem verse"
            style="grid-row: 4 / 7;"
        >
            {{ t('async.text.no_chapter') }}
        </p>
        <!-- Copyright -->
        <footer
            v-if="chapter.verses.length !== 0"
            :style="typeof chapterMeta['rows'][chapterMeta['rows'].length - 1] !== 'undefined' ? `grid-row-start:${chapterMeta['rows'][chapterMeta['rows'].length - 1][3]};grid-row-end:${chapterMeta['rows'][chapterMeta['rows'].length - 1][3] + 1};`:''"
        >
            <p
                class="copyright"
                dir="ltr"
                v-html="chapter.bible.copyright.replace(/(?:\r\n|\r|\n)/g, '<br>')"
            >
            </p>
        </footer>
    </article>
</template>
