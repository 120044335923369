import {computed,} from "vue"
import {useAnalytics,} from "@/assets/js/src/util/composables/useAnalytics"
import {useTextFormats,} from "@/assets/js/src/modules/text/_composables/useTextFormats"
import {useRoute,} from "vue-router"
import {
    SELECT_TYPE_MULTIPLE,
    SELECT_TYPE_RANGE,
    SELECT_TYPE_SINGLE,
    useTextStore,
} from "@/assets/js/src/modules/text/_pinia/text"
import {getActivePinia, storeToRefs,} from "pinia"
import {useTextInfo,} from "@/assets/js/src/modules/text/_composables/useTextInfo"

export function useTextSelect () {
    const activePinia = getActivePinia()
    const currentRoute = useRoute()
    const {bsa,} = useAnalytics()
    const {textFormats,} = useTextFormats()

    const isOneVerseLine = computed(()=> {
        return currentRoute.meta.isCompare || textFormats.value.oneVerseLine
    })

    const textStore = useTextStore(activePinia)
    const {
        chapters,
        selectedVersesAsArray: selectedVerses,
        isSelectedVerse,
    } = storeToRefs(textStore)

    const {
        chapterIds,
    } = useTextInfo()

    const copyHandler = function (event) {
        try {
            const selection = document.getSelection()

            let text = selection.toString()
            text = text
                .replace(/\n/gm, '')
                .replace(/\u0003(\s)?\u0001/gm, '\n\n\u0001')
                .replace(/\u0001(.*?)\u0002/gm, '$1\n')
            if (isOneVerseLine.value) {
                text = text
                    .replace(/\u0003(\s)?\u0002/gm, '\n')
            }
            event.clipboardData.setData('text/plain', text
                .replace(/(\u0003|\u0002|\u0001)/gm, '')
            )
            event.preventDefault()
            bsa.event({
                eventCategory: 'text-selection',
                eventAction: 'copied',
            })
        } catch (e) {
            // eslint-disable-next-line no-empty
        }
    }

    const selectSection = function (event, selectedHeadingVerse, chapterId) {
        // Range finden
        let verseEndFound = chapters.value[chapterIds.value.indexOf(chapterId)].chapter.verses.reduce((found, verse, index, verses) => {
            if (found && typeof found !== 'object') {
                if (typeof verse.subheading !== 'undefined' || typeof verse.heading !== 'undefined') {
                    found = verses[index - 1]
                } else if (index === verses.length - 1) {
                    found = verse
                }
            }
            if (selectedHeadingVerse.verse === verse.verse) {
                return true
            }

            return found
        }, false)

        let verseEnd = verseEndFound.verse
        if (typeof verseEndFound.verseEnd !== 'undefined') {
            verseEnd = verseEndFound.verse_end
        }

        // Abwählen der Range prüfen
        let selection = selectedVerses.value
        let rangeAlreadySelected = selection.length === 1 && selection[0][0] === selectedHeadingVerse.verse && selection[0][1] === verseEnd

        let range = []
        for (let i = selectedHeadingVerse.verse; i <= verseEnd; i++) {
            range.push(i)
        }

        textStore.setSelectedVerses({selectedVerses: range, selected: !rangeAlreadySelected,})
    }

    const selectVerse = function (event, verse) {
        event.stopPropagation()
        event.preventDefault()

        // Klick auf Querverweise ignorieren
        if (event.target.tagName === 'A' || (event.target.tagName === 'SUP' && globalThis.isTouchDevice)) {
            return
        }

        let type = SELECT_TYPE_SINGLE
        if (event.shiftKey) {
            bsa.event({
                eventCategory: 'shortcuts',
                eventAction: 'shift (Bereichsauswahl in Textansicht)',
            })
            type = SELECT_TYPE_RANGE
            if (!import.meta.env.SSR) {
                let sel = window.getSelection ? window.getSelection() : document.selection
                if (sel) {
                    if (sel.removeAllRanges) {
                        sel.removeAllRanges()
                    } else if (sel.empty) {
                        sel.empty()
                    }
                }
            }
        } else if (event.metaKey || event.ctrlKey) {
            bsa.event({
                eventCategory: 'shortcuts',
                eventAction: 'ctrl (Mehrfachauswahl in Textansicht)',
            })
            type = SELECT_TYPE_MULTIPLE
        }

        textStore.setSelectedVerse({
            verse: verse,
            selected: !isSelectedVerse.value(verse.verse),
            type,
        })
    }

    return {
        selectSection,
        selectVerse,
        copyHandler,
    }
}